import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import {
  APPLICATION,
  DEFAULTS,
  DEFAULT_PAGES_ELEMENTS,
  DEFAULT_THEME_CONFIG_MISC,
  ELEMENTS,
  FONTS,
  PACKAGES,
  PAGES,
  THEMES,
  TIMES,
  iFramesrcs,
} from "./Consts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function App() {
  const [theme, setTheme] = useState(THEMES.DARK);
  const [iFrameSrc, setiFrameSrc] = useState(iFramesrcs[theme]);
  const [primaryColor, setPrimaryColor] = useState(
    DEFAULTS(theme).PRIMARY_COLOR
  );
  const [secondaryColor, setSecondaryColor] = useState(
    DEFAULTS(theme).SECONDARY_COLOR
  );
  const [bodyBg, setBodyBg] = useState(DEFAULTS(theme).BODY_BG);
  const [itemBg, setItemBg] = useState(DEFAULTS(theme).ITEM_BG);
  const [menubarBg, setMenuBarBg] = useState(DEFAULTS(theme).MENUBAR_BG);
  const [tabSelectedIndex, setTabSelectedIndex] = useState(0);

  const [_package, setPackage] = useState(PACKAGES.BASIC);
  const [_app, setApplication] = useState(APPLICATION.HOME);
  const [_font, setFont] = useState(FONTS[0].value);
  const [elements, setElement] = useState({});
  const [pages, setPages] = useState({});
  const [miscSettings, setMiscSettings] = useState({});
  const [themeConfigMisc, setThemeConfigMisc] = useState(
    DEFAULT_THEME_CONFIG_MISC(_app)
  );

  const [importTheme, setImportTheme] = useState("");
  const [importedThemes, setImportedThemes] = useState([]);
  const [pickedTheme, setPickedTheme] = useState();

  const iRef = useRef(null);

  const setToDefaults = useCallback(() => {
    setPrimaryColor(DEFAULTS(theme).PRIMARY_COLOR);
    setSecondaryColor(DEFAULTS(theme).SECONDARY_COLOR);
    setMenuBarBg(DEFAULTS(theme).MENUBAR_BG);
    setBodyBg(DEFAULTS(theme).BODY_BG);
    setItemBg(DEFAULTS(theme).ITEM_BG);
    setThemeConfigMisc(DEFAULT_THEME_CONFIG_MISC(_app));
    setPages({
      ...pages,
      ...DEFAULT_PAGES_ELEMENTS(_app).pages,
    });
    setElement({
      ...elements,
      ...DEFAULT_PAGES_ELEMENTS(_app).elements,
    });
    iRef.current.contentWindow.postMessage({ app: _app });
  }, [theme, _app, pages, elements]);

  useEffect(() => {
    setiFrameSrc(iFramesrcs[theme]);
  }, [theme, setToDefaults]);

  useEffect(() => {
    if (_package === PACKAGES.BASIC) {
      setTabSelectedIndex(0);
    }
  }, [_package]);

  useEffect(() => {
    if (_app === APPLICATION.APPLIANCES) {
      setTheme(THEMES.LIGHT);
    }
    if (_app === APPLICATION.HOME) {
      setTheme(THEMES.DARK);
    }
  }, [_app]);

  useEffect(() => {
    if (iRef.current.contentWindow) {
      console.log(elements);
      iRef.current.contentWindow.postMessage(
        {
          primaryColor,
          secondaryColor,
          bodyBg,
          itemBg,
          menubarBg,
          font: _font,
          themeConfig: {
            elements,
            pages,
            misc: themeConfigMisc,
          },
          ...miscSettings,
          app: _app,
        },
        "*"
      );
    }
  }, [
    primaryColor,
    secondaryColor,
    bodyBg,
    itemBg,
    menubarBg,
    iRef,
    _font,
    elements,
    pages,
    miscSettings,
    themeConfigMisc,
    _app,
  ]);

  const oniFrameLoad = () => {
    setToDefaults();
  };

  const exportTheme = () => {
    let themeName = prompt("Please enter theme name", "");
    if (themeName != null) {
      download(
        `${themeName}.txt`,
        btoa(
          JSON.stringify({
            theme: theme,
            themeName,
            primaryColor,
            secondaryColor,
            bodyBg,
            itemBg,
            menubarBg,
            font: _font,
            themeConfig: {
              pages,
              elements,
              misc: themeConfigMisc,
            },
            app: _app,
          })
        )
      );
      // _anchor.click();
    }
  };

  const submitTheme = () => {
    var partner = prompt("Please enter your company name", "");
    if (partner != null) {
      let _anchor = document.getElementById("submitTheme");
      let body = {
        theme: theme,
        primaryColor,
        secondaryColor,
        bodyBg,
        itemBg,
        menubarBg,
        font: _font,
        themeConfig: {
          pages,
          elements,
          misc: themeConfigMisc,
        },
        app: _app,
      };
      _anchor.href = `mailto:support@kiot.io?subject=Theme%20Config%20for%20${partner}&body=${encodeURIComponent(
        btoa(JSON.stringify(body))
      )}`;
      _anchor.click();
    }
  };

  const download = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const readFile = ({ target: { files } }) => {
    if (files && files[0]) {
      var FR = new FileReader();

      FR.addEventListener("load", function (e) {
        setMiscSettings({
          ...miscSettings,
          homeBgImg: e.target.result,
        });
      });

      FR.readAsDataURL(files[0]);
    }
  };

  const loadFile = () => {
    let file, fr;

    if (typeof window.FileReader !== "function") {
      alert("This feature isn't supported on this browser yet.");
      return;
    }

    if (!importTheme) {
      alert(
        "This browser doesn't seem to support the `files` property of file inputs."
      );
    } else if (!importTheme[0]) {
      alert("Please select a file before clicking 'Load'");
    } else {
      file = importTheme[0];
      fr = new FileReader();
      fr.onload = receivedText;
      fr.readAsText(file);
    }

    function receivedText() {
      showResult(fr, "Text");

      fr = new FileReader();
      fr.onload = receivedBinary;
      fr.readAsBinaryString(file);
    }

    function receivedBinary() {
      showResult(fr, "Binary");
    }
  };

  const showResult = (fr, label) => {
    var markup, result, n, aByte, byteStr;

    markup = [];
    result = fr.result;
    for (n = 0; n < result.length; ++n) {
      aByte = result.charCodeAt(n);
      byteStr = aByte.toString(16);
      if (byteStr.length < 2) {
        byteStr = "0" + byteStr;
      }
      markup.push(byteStr);
    }
    if (label === "Text") {
      // let text = markup.join('');
      let _importedTheme = JSON.parse(atob(result));
      setImportedThemes([
        ...importedThemes,
        {
          ..._importedTheme,
          _id: Date.now() + Math.random(),
        },
      ]);
      setImportTheme("");
      alert("Theme loaded, pick from 'import from themes'");
    }
    // alert(JSON.parse(atob(text)));
  };

  const applyTheme = (theme) => {
    setTheme(theme.theme);
    setPrimaryColor(theme.primaryColor);
    setSecondaryColor(theme.secondaryColor);
    setMenuBarBg(theme.menubarBg);
    setItemBg(theme.setItemBg);
    setBodyBg(theme.bodyBg);
    setFont(theme.font);
    if (theme.themeConfig) {
      setPages(theme.themeConfig.pages);
      setElement(theme.themeConfig.elements);
      setThemeConfigMisc(theme.themeConfig.misc);
    }
    if (theme.app) {
      setApplication(theme.app);
    }
  };

  return (
    <div className="App">
      <div className="content">
        <div className="row">
          <div className="col-sm-4 col-xs-12 mx-auto">
            {/* <img src="img/frame.png" alt=""></img> */}
            <div className="iframe-frame">
              <iframe
                src={iFrameSrc}
                title="app"
                className="iframeContainer"
                ref={iRef}
                onLoad={oniFrameLoad}
              ></iframe>
            </div>
          </div>
          <div className="col-sm-8 col-12 main-col">
            <div className="main-heading"> KIOT Theme Customiser</div>
            <div className="row">
              <div class="picker col-md-3 col-4">
                <label for="favcolor" class="headerText2">
                  Package
                </label>
                <div>
                  <select
                    className="select"
                    onChange={(e) => setPackage(e.target.value)}
                    value={_package}
                  >
                    <option value={PACKAGES.BASIC}>Basic</option>
                    <option value={PACKAGES.ADVANCED}>Advanced</option>
                  </select>
                </div>
              </div>
              <div class="picker col-md-3 col-4">
                <label for="favcolor" class="headerText2">
                  Theme
                </label>
                <div>
                  <select
                    className="select"
                    onChange={(e) => setTheme(e.target.value)}
                    value={theme}
                  >
                    <option value={THEMES.DARK}>Dark</option>
                    <option value={THEMES.LIGHT}>Light</option>
                  </select>
                </div>
              </div>
              {_package === PACKAGES.ADVANCED ? (
                <div class="picker col-md-3 col-4">
                  <label for="favcolor" class="headerText2">
                    Application
                  </label>
                  <div>
                    <select
                      className="select"
                      onChange={(e) => setApplication(e.target.value)}
                      value={_app}
                    >
                      <option value={APPLICATION.HOME}>Home Automation</option>
                      <option value={APPLICATION.APPLIANCES}>
                        Smart Appliances
                      </option>
                    </select>
                  </div>
                </div>
              ) : null}
            </div>

            <Tabs
              selectedIndex={tabSelectedIndex}
              onSelect={(index) => setTabSelectedIndex(index)}
            >
              <TabList>
                <Tab>
                  <p>Colors</p>
                </Tab>
                <Tab disabled={_package === PACKAGES.BASIC}>
                  <p>Fonts</p>
                </Tab>
                <Tab disabled={_package === PACKAGES.BASIC}>
                  <p>Elements</p>
                </Tab>
                <Tab disabled={_package === PACKAGES.BASIC}>
                  <p>Pages</p>
                </Tab>
                <Tab>
                  <p>Submit</p>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="row">
                  <div className="col-3 text-center">
                    <div className="colorpicker-container">
                      <div className="mb-0-4rem"> Primary Color </div>
                      <HexColorPicker
                        color={primaryColor}
                        onChange={setPrimaryColor}
                      />
                      <HexColorInput
                        color={primaryColor}
                        onChange={setPrimaryColor}
                      />
                    </div>
                  </div>
                  <div className="col-3 text-center">
                    <div className="colorpicker-container">
                      <div className="mb-0-4rem"> Secondary Color </div>
                      <HexColorPicker
                        color={secondaryColor}
                        onChange={setSecondaryColor}
                      />
                      <HexColorInput
                        color={secondaryColor}
                        onChange={setSecondaryColor}
                      />
                    </div>
                  </div>
                  {_package === PACKAGES.ADVANCED ? (
                    <>
                      <div className="col-3 text-center">
                        <div className="colorpicker-container">
                          <div className="mb-0-4rem"> Body Bg Color </div>
                          <HexColorPicker color={bodyBg} onChange={setBodyBg} />
                          <HexColorInput color={bodyBg} onChange={setBodyBg} />
                        </div>
                      </div>
                      <div className="col-3 text-center">
                        <div className="colorpicker-container">
                          <div className="mb-0-4rem"> Item Bg Color </div>
                          <HexColorPicker color={itemBg} onChange={setItemBg} />
                          <HexColorInput color={itemBg} onChange={setItemBg} />
                        </div>
                      </div>
                      <div className="col-3 text-center">
                        <div className="colorpicker-container">
                          <div className="mb-0-4rem"> Menubar Bg Color </div>
                          <HexColorPicker
                            color={menubarBg}
                            onChange={setMenuBarBg}
                          />
                          <HexColorInput
                            color={menubarBg}
                            onChange={setMenuBarBg}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  {FONTS.map((font) => (
                    <div className="col-4 fontname-container">
                      <div
                        className={
                          "fontname " +
                          (_font === font.value ? "font-selected" : "")
                        }
                        onClick={() => setFont(font.value)}
                      >
                        {font.name}
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row m-1rem">
                  {ELEMENTS.map((e) => {
                    return (
                      <div className="col-6 mb-1rem">
                        <div>
                          <label for="favcolor" class="headerText2">
                            {e.name}
                          </label>
                        </div>
                        <select
                          className="select"
                          onChange={(ev) =>
                            setElement({
                              ...elements,
                              [e.value]: ev.target.value,
                            })
                          }
                          value={elements[e.value]}
                        >
                          {e.options.map((op) => (
                            <option value={op.value}>{op.name}</option>
                          ))}
                        </select>
                      </div>
                    );
                  })}
                  {_app === APPLICATION.APPLIANCES ? (
                    <>
                      <div className="cursor-pointer col-6">
                        <input
                          type="checkbox"
                          className="chk-box"
                          id="chb01"
                          value={!themeConfigMisc["dont_show_tab3"]}
                          onChange={(e) => {
                            setThemeConfigMisc({
                              ...themeConfigMisc,
                              dont_show_tab3: !e.target.checked,
                            });
                          }}
                        ></input>
                        <label for="chb01">Show Scenes/Automations Tab</label>
                      </div>

                      <div className="cursor-pointer col-6">
                        <input
                          type="checkbox"
                          className="chk-box"
                          id="chb02"
                          value={!themeConfigMisc["dont_show_tab4"]}
                          onChange={(e) => {
                            setThemeConfigMisc({
                              ...themeConfigMisc,
                              dont_show_tab4: !e.target.checked,
                            });
                          }}
                        ></input>
                        <label for="chb02">Show Remotes Tab</label>
                      </div>
                    </>
                  ) : null}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row m-1rem">
                  {PAGES.map((e) => {
                    if (_app === APPLICATION.APPLIANCES) {
                      if (e.value === "ROOMS" || e.value == "SWITCHES")
                        return <></>;
                    }
                    return (
                      <div className="col-6 mb-1rem">
                        <div>
                          <label for="favcolor" class="headerText2">
                            {e.name}
                          </label>
                        </div>
                        <select
                          className="select"
                          onChange={(ev) =>
                            setPages({
                              ...pages,
                              [e.value]: ev.target.value,
                            })
                          }
                          value={pages[e.value]}
                        >
                          {e[
                            _app === APPLICATION.APPLIANCES &&
                            e.options_appliances
                              ? "options_appliances"
                              : "options"
                          ].map((op) => (
                            <option value={op.value}>{op.name}</option>
                          ))}
                        </select>
                        {e.value === "HOME" &&
                        (pages["HOME"] === "3" || pages["HOME"] === "4") ? (
                          <div className="homebg-ops">
                            <div>
                              <label for="favcolor" class="headerText2">
                                Current Time
                              </label>
                            </div>
                            <select
                              className="select mb-1rem"
                              value={miscSettings["welcome_bg"]}
                              onChange={(e) =>
                                setMiscSettings({
                                  ...miscSettings,
                                  welcome_bg: e.target.value,
                                })
                              }
                            >
                              {TIMES.map((op) => (
                                <option value={op.value}>{op.name}</option>
                              ))}
                            </select>
                            <div>
                              <label for="favcolor" class="headerText2">
                                Upload Custom Bg Image
                              </label>
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={readFile}
                              ></input>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row m-1rem">
                  <div className="mt-1rem col-6">
                    <label for="favcolor" class="headerText2">
                      Import your theme
                    </label>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {console.log(e.target.files);setImportTheme(e.target.files)}}
                    ></input>
                    <button className="small-btn" onClick={loadFile}>
                      Import Theme
                    </button>
                  </div>
                  <div className="mt-1rem col-6">
                    {importedThemes && importedThemes.length ? (
                      <>
                        <div>
                          <label for="favcolor" class="headerText2">
                            Pick from Imported Themes
                          </label>
                        </div>
                        <select
                          className="select"
                          onChange={(ev) => {
                            setPickedTheme(ev.target.value);
                            let theme;
                            for (let i of importedThemes) {
                              if (i._id === ev.target.value) {
                                theme = i;
                                break;
                              }
                            }
                            applyTheme(theme);
                          }}
                          value={pickedTheme}
                        >
                          {importedThemes.map((e) => {
                            return <option value={e._id}>{e.themeName}</option>;
                          })}
                        </select>
                      </>
                    ) : null}
                  </div>
                  <div className="mt-1rem col-6">
                    <button onClick={exportTheme}>Export Theme</button>
                  </div>
                  <div className="mt-1rem col-6">
                    <button onClick={submitTheme}>Submit Theme</button>
                    <a
                      type="button"
                      href="#"
                      className="display-none"
                      id="submitTheme"
                    ></a>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
