const ELEMENTS = [
  //   PRIMARY_COLOR: 1,
  //   SECONDARY_COLOR: 2,
  //   BODY_BG: 3,
  //   ITEM_BG: 4,
  //   MENUBAR_BG: 5,
  {
    name: "Tab Bar",
    value: "TAB_BAR",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
      {
        name: "Theme 5",
        value: 5,
      },
      {
        name: "Theme 6",
        value: 6,
      },
    ],
  },
  {
    name: "Alerts",
    value: "ALERTS",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
    ],
  },
  {
    name: "Items",
    value: "ITEMS",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
    ],
  },
  {
    name: "Buttons",
    value: "BUTTONS",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
    ],
  },
];

const PAGES = [
  {
    name: "Home Page",
    value: "HOME",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
      {
        name: "Theme 7",
        value: 7
      }
    ],
    options_appliances: [
      {
        name: "Classic",
        value: 5
      },
      {
        name: "Theme 2",
        value: 6
      },
    ]
  },
  {
    name: "Devices Page",
    value: "ROOMS",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
    ],
  },
  {
    name: "Switches Page",
    value: "SWITCHES",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
    ],
  },
  {
    name: "Scenes Page",
    value: "SCENES",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      }
    ],
  },
  {
    name: "Remotes Page",
    value: "REMOTES",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
    ],
  },
  {
    name: "Settings Page",
    value: "SETTINGS",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      }
    ],
  },
  {
    name: "Add A Device Page",
    value: "DEVICE_CATALOG",
    options: [
      {
        name: "Classic",
        value: 1,
      },
      {
        name: "Theme 2",
        value: 2,
      },
      {
        name: "Theme 3",
        value: 3,
      },
      {
        name: "Theme 4",
        value: 4,
      },
    ],
  },
];

const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

const PACKAGES = {
  BASIC: "BASIC",
  ADVANCED: "ADVANCED",
};

const FONTS = [
  {
    name: "Classic",
    path: "",
    value: "SanFranciscoLight",
  },
  {
    name: "Lato",
    path: "",
    value: "Lato",
  },
  {
    name: "Montserrat",
    path: "",
    value: "Montserrat",
  },
  {
    name: "OpenSans",
    path: "",
    value: "OpenSans",
  },
  {
    name: "Roboto",
    path: "",
    value: "Roboto",
  },
  {
    name: "Ubuntu",
    path: "",
    value: "Ubuntu",
  },
];

const APPLICATION = {
  HOME: "HOME",
  APPLIANCES: "APPLIANCES",
};

const iFramesrcs = {
  [THEMES.LIGHT]: "https://light1.kiot.io",
  [THEMES.DARK]: "https://dark1.kiot.io",
  // [THEMES.DARK]: "http://localhost:8100",
  // [THEMES.LIGHT]: "http://localhost:8100",
};

const DEFAULTS = (theme) => {
  if (theme === THEMES.DARK) {
    return {
      PRIMARY_COLOR: "#FFCC00",
      SECONDARY_COLOR: "#FF9500",
      BODY_BG: "#0d0d0d",
      ITEM_BG: "#1c1c1d",
      MENUBAR_BG: "#181818",
    };
  } else if (theme === THEMES.LIGHT) {
    return {
      PRIMARY_COLOR: "#e52e2e",
      SECONDARY_COLOR: "#e52e2e",
      BODY_BG: "#efefef",
      ITEM_BG: "#ffffff",
      MENUBAR_BG: "#ffffff",
    };
  }
};

const DEFAULT_PAGES_ELEMENTS = (_app) => {
  if (_app === APPLICATION.HOME) {
    return {
      pages: {
        HOME: 1,
      },
      elements: {
        TAB_BAR: 1,
      },
    };
  } else if (_app === APPLICATION.APPLIANCES) {
    return {
      pages: {
        HOME: 5,
      },
      elements: {
        TAB_BAR: 4,
      },
    };
  }
};

const DEFAULT_THEME_CONFIG_MISC = (_app) => {
  if (_app === APPLICATION.HOME) {
    return {
      dont_show_tab2: false,
      dont_show_tab3: false,
      dont_show_tab4: false,
      dont_show_device_cat: false,
    };
  } else if (_app === APPLICATION.APPLIANCES) {
    return {
      dont_show_tab2: true,
      dont_show_tab3: true,
      dont_show_tab4: true,
      dont_show_device_cat: true,
    };
  }
};

const getWelcomeMessage = () => {
  let now = new Date();
  if (now.getHours() >= 4 && now.getHours() < 12) {
    return "bg-morning";
  } else if (now.getHours() >= 12 && now.getHours() < 17) {
    return "bg-afternoon";
  } else {
    if (now.getHours() >= 17 && now.getHours() < 20) {
      return "bg-evening";
    } else {
      return "bg-night";
    }
  }
};

const TIMES = [
  {
    name: "Now",
    value: getWelcomeMessage(),
  },
  {
    name: "Morning",
    value: "bg-morning",
  },
  {
    name: "Afternoon",
    value: "bg-afternoon",
  },
  {
    name: "Evening",
    value: "bg-evening",
  },
  {
    name: "Night",
    value: "bg-night",
  },
];

export {
  THEMES,
  ELEMENTS,
  DEFAULTS,
  iFramesrcs,
  PACKAGES,
  APPLICATION,
  FONTS,
  PAGES,
  TIMES,
  DEFAULT_THEME_CONFIG_MISC,
  DEFAULT_PAGES_ELEMENTS
};
